/* global process */

/**
 * Deliberately not using any material stuff here so it isn't loaded before we need it
 */

import React, { useEffect, Fragment, useState, useCallback } from 'react';
import { useAuth0 } from 'components/Auth/react-auth0-wrapper';
import * as PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

function delete_cookie(name) {
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// List of servers that don't follow the region naming convention
// Should eventually be replaced by a choose your region type site
const AU_SERVERS = [
  'dev.adgile.media',
  'dev.adgile.com',
  'dev.catalyst.adgile.com',
  'stage.adgile.media',
  'stage.adgile.com',
  'stage.catalyst.adgile.com',
  'live.adgile.media',
  'live.adgile.com',
  'live.catalyst.adgile.com',
  'foresight.adgile.media',
  'portal.adgile.media',
  'au.portal.adgile.media',
  'catalyst.adgile.com',
  'au.catalyst.adgile.com'
];

function checkRegion(auth0) {
  if (window.location.hostname.toLowerCase() === 'localhost')
    return;
  const params = new URLSearchParams(window.location.search);
  if (params.has('ignore-region')) {
    return;
  }

  const user = auth0.user;
  let region = user['https://adgile.com/region'];
  if (!region) {
    region = user['https://adgile.media/region'];
  }
  if (!region) {
    region = 'AU';
  }
  region = region.toLowerCase();

  if (!window.location.hostname.toLowerCase().startsWith(`${region}.`)) {
    if (region !== 'au' || !AU_SERVERS.includes(window.location.hostname.toLowerCase())) {
      window.location.href = `https://${region}.catalyst.adgile.com`;
    }
  }
}

const PrivateBoundary = function({ children }) {
  const auth0 = useAuth0();
  const { isAuthenticated, loginWithRedirect } = auth0;
  const [ errorDescription, setErrorDescription ] = useState(null);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    let message = params.get('error_description') || error;
    switch (message) {
      case 'ESOCKETTIMEDOUT':
        message = 'There was an error contacting the server. Please try again.';
        break;
    }
    setErrorDescription(message);
    if (isAuthenticated) {
      checkRegion(auth0);
    } else if (!error) {
      try {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
          if (cookie.startsWith(' a0.spajs.txs.')) {
            const name = cookie.split('=', 1)[0];
            delete_cookie(name);
          }
        }

        loginWithRedirect({
          appState: { targetUrl: location.href }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [isAuthenticated, loginWithRedirect]);

  const handleOnClick = useCallback((e) => {
    e.preventDefault();
    auth0.logout({
      returnTo: window.location.origin,
      client_id: process.env.CLIENT_ID
    });
    return false;
  })

  return (<Fragment>{ isAuthenticated ? children : (

    <div className="splash">
      <div>
        <img
          src={'/images/logos/adgile-catalyst-light.png'}
          width="300px"
        />
      </div>
      { errorDescription ? (
        <Fragment>
          <div>{errorDescription}</div>
          <div>
            <Link
              href="/"
              onClick={handleOnClick}
              color="inherit"
            >
              Logout
            </Link> | <Link color="inherit" href="/">Retry</Link>
          </div>
        </Fragment>
      ) : (
        <div>LOADING ...</div>
      ) }
    </div>
  )}</Fragment>);
};

PrivateBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ])
}

export default PrivateBoundary;